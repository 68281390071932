<template>
  <v-row>
    
    <v-col cols="12" md="4" >
      <v-radio-group v-model="value.tipo" row 
       dense
        :rules="[
                this.$validators.string.required,
          ]">
        <v-radio
          label="Lista Matrícula"
          value="LM"
          :checked=true
        ></v-radio>
        <v-radio
          label="Histórico Matrícula"
          value="HM"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" md="4">
       <v-text-field label="Mês/Ano" v-show="value.tipo === 'HM'"
                    dense
                    type="month"
                    v-model="value.mesAno"
                    :value="this.mesAnoAtual"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
        <v-checkbox v-show="value.tipo === 'LM'"
          dense
          label="Exibir Histórico"
          v-model="value.exibirHistorico"
          value="S"></v-checkbox>
    </v-col>
    <v-col cols="12" md="4">
      <lookup-dominio label="Status"
                      v-model="value.status"
                      :type="lookups.status"
                      :hidden-clear="false"/>
    </v-col>    
    <v-col cols="12" md="4">
      <v-text-field label="Nome Aluno"
                    dense
                    v-model="value.nome"
                    :rules="[
                        v => this.$validators.string.greaterThanOrEquals(v, 1),
                        v => this.$validators.string.lessThanOrEquals(v, 100),
                    ]"/>
    </v-col>
    <v-col cols="12" md="4">
      <lookup-dominio label="Tipo Plano"
                      hint="Selecione qual o tipo do plano"
                      v-model="value.tipoPlano"
                      :type="lookups.tipoPlano"
                      :hidden-clear="false"/>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          :hidden-clear="false"
          label="Local"
          popup-label="Selecione um local"
          :id.sync="value.idLocal"
          :descricao.sync="value.descricao_idLocal"
          :value="local"
          :loader-fn="loaderLocal"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum local encontrado
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          :hidden-clear="false"
          label="Curso"
          popup-label="Selecione um curso"
          :id.sync="value.idCurso"
          :descricao.sync="value.descricao_idCurso"
          :value="curso"
          :loader-fn="loaderCurso"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum curso encontrado
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          :hidden-clear="false"
          label="Quadra"
          popup-label="Selecione uma Quadra"
          :id.sync="value.idQuadra"
          :descricao.sync="value.descricao_idQuadra"
          :value="quadra"
          :loader-fn="loaderQuadras"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhuma Quadra encontrada
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          :hidden-clear="false"
          label="Horário"
          popup-label="Selecione um Horário"
          :id.sync="value.idHorario"
          :horaInicio.sync="value.horaInicio_idHorario"
          :value="horario"
          :loader-fn="loaderHorarios"
          item-key="id"
          item-text="horaInicio">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Horário encontrado
          </p>
        </template>
      </search-box>
    </v-col>
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import {findAll as findAllCursos} from "../../../../api/cursos";
import {findAll as findAllLocal} from "../../../../api/locais";
import {findAll as findAllHorarios} from "../../../../api/horarios";
import {findAll as findAllQuadras} from "../../../../api/quadras";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import moment from 'moment';

export default {
  name: "RelatorioHistoricoMatriculaForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO,
        tipoPlano: ItemDeDominio.TIPO_PLANO,
      },
      loaderCurso(page, search) {
        return findAllCursos(page, {
          descricao: search
        })
      },
      loaderLocal(page, search) {
        return findAllLocal(page, {
          descricao: search
        })
      },
      loaderHorarios(page, search) {
        return findAllHorarios(page, {
          descricao: search
        })
      },
      loaderQuadras(page, search) {
        return findAllQuadras(page, {
          descricao: search
        })
      },
    }
  },
  computed: {
    curso() {
      if (this.value.idCurso == null) return null;
      return {
        id: this.value.idCurso,
        descricao: this.value.descricao_idCurso
      }
    },
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricao_idLocal
      }
    },
    horario() {
      if (this.value.idHorario == null) return null;
      return {
        id: this.value.idHorario,
        horaInicio: this.value.horaInicio_idHorario
      }
    },
    quadra() {
      if (this.value.idQuadra == null) return null;
      return {
        id: this.value.idQuadra,
        descricao: this.value.descricao_idQuadra
      }
    },
    mesAnoAtual: function () {
      if (typeof this.value.mesAno === "undefined"){
        this.value.mesAno = moment().format('YYYY-MM');
      }
    },
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>